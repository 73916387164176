// in src/eventInfo.js
import React from 'react';
import {DateField, ShowButton, Show, SimpleShowLayout, List, Datagrid, TextField, Filter, TextInput } from 'react-admin';
import ReactJson from 'react-json-view';
//<DateField  source="time" showTime options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit', minute:'2-digit', second:'2-digit', timeZoneName:'short' }} />

export const Event1List = (props) => (
<List title="source: zzz-sce" {...props} perPage={100} filters={<Event1Filter/>} sort={{ field: 'time', order: 'DESC' }}  >
        <Datagrid>
            <DateField  source="time" showTime options={{ weekday: 'short', year: 'numeric', month: 'short', day: 'numeric', hour:'2-digit', minute:'2-digit', second:'2-digit', timeZoneName:'short' }} />
            <TextField source="source" />
            <TextField source="detail-type" />
            <TextField source="detail" maxLength={10}/>
            <ShowButton />
        </Datagrid>
    </List> 
);

//const EventPagination = props => <Pagination rowsPerPageOptions={} {...props} />


const Event1Filter = (props) => (
    <Filter {...props}>
        <TextInput label="Search" source="q" alwaysOn />
        <TextInput label="detail-type" source="detail-type" />
    </Filter>
);

const Event1Title = ({ record }) => {
    return <span>Event {record ? `"${record.id}"` : ''}</span>;
};

// export const EventEdit = (props) => (
//     <Edit title={<EventTitle />} {...props}>
//         <SimpleForm>
//             <DisabledInput source="id" />
//             <DisabledInput source="eventType" />
//         </SimpleForm>
//     </Edit>
// );

const JsonTextField = ({ source, record = {} }) => <span><font size="4"><ReactJson src={record[source]} name='' iconStyle='square' displayObjectSize={false} displayDataTypes={false}/></font></span>;
export default JsonTextField;

export const Event1Show = (props) => (
    <Show title={<Event1Title />} {...props}>
        <SimpleShowLayout>
            <DateField source="time" showTime options={{ weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour:'2-digit', minute:'2-digit', second:'2-digit', timeZoneName:'short' }} />
            <TextField source="source" />
            <TextField source="detail-type" />
            <span>-------------------------------------------------</span>
            <JsonTextField source="detail" />            
        </SimpleShowLayout>
    </Show>
);